import {
    useState,
} from 'react';

import Query, {
    IQueryDescription,
} from '../Query';

type IResult<IQuery> = [IQuery, (query: IQuery) => void];
type IQueryPusher = (search: string) => Promise<any> | any;

interface IParams<IQuery extends Record<string, any>> {
    query: string;
    queryDescription: IQueryDescription<IQuery>;
    queryPusher: IQueryPusher;
}

export default function<IQuery extends Record<string, any>>(params: IParams<IQuery>): IResult<IQuery> {
    const [query, setQuery] = useState<IQuery>(Query.parse(params.query, params.queryDescription));

    const changeQuery = (newQuery: IQuery) => {
        const newStringQuery = Query.stringify(newQuery);

        if (newStringQuery !== params.query) {
            params.queryPusher(newStringQuery);
        }
        if (newStringQuery !== Query.stringify(query)) {
            setQuery(newQuery);
        }
    };

    return [query, changeQuery];
}
