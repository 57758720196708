import {
    IProps,
    IRef,
} from './types';

import React, {
    forwardRef,
    useImperativeHandle,
    useState,
} from 'react';

import {
    formatPrice,
} from 'helpers/functions';

import {
    NumberInput,
} from 'components/inputs';

import {
    ERROR,
} from './constants';
import {
    formatError,
} from './functions';

const AmountInput = forwardRef<IRef, IProps>((props, ref) => {
    const [value, setValue] = useState<number | null>(null);
    const [error, setError] = useState<ERROR | null>(null);

    const onChangeValue = (value: number | null) => {
        setValue(value);
        setError(null);
    };

    useImperativeHandle(ref, () => {
        return {
            getValue(): number {
                return value ?? 0;
            },
            validate(): boolean {
                if (value === null) {
                    setError(ERROR.IS_REQUIRED);

                    return false;
                }
                if (value < props.paymentMethod.minPaymentAmount) {
                    setError(ERROR.TOO_FEW);

                    return false;
                }
                if (value > props.paymentMethod.maxPaymentAmount) {
                    setError(ERROR.TOO_MUCH);

                    return false;
                }

                setError(null);

                return true;
            },
        };
    });

    return (
        <NumberInput
            className={props.className}
            label={`Send from ${formatPrice(props.paymentMethod.minPaymentAmount)} ${props.paymentMethod.currency} to ${formatPrice(props.paymentMethod.maxPaymentAmount)} ${props.paymentMethod.currency}`}
            value={value}
            isDisabled={props.isDisabled}
            isError={!!error}
            errorMessage={formatError(error)}
            onChangeValue={onChangeValue}
        />
    );
});

export default AmountInput;
