import React, {
    FC,
} from 'react';
import {
    BrowserRouter,
    Route,
    Routes,
} from 'react-router-dom';

import Payment from './pages/Payment';
import PaymentMethods from './pages/PaymentMethods';
import {
    useRedirectProtocol,
} from './tools/hooks';

const App: FC = () => {
    useRedirectProtocol();

    return (
        <BrowserRouter>
            <Routes>
                <Route
                    path={'/'}
                    element={<PaymentMethods/>}
                />
                <Route
                    path={'/:paymentMethodId'}
                    element={<Payment/>}
                />
            </Routes>
        </BrowserRouter>
    );
};

export default App;
