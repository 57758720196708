import {
    IProps,
} from './types';

import classNames from 'classnames';
import React, {
    FC,
} from 'react';
import ClipLoader from 'react-spinners/ClipLoader';

import styles from './Loading.module.scss';

import {
    Header,
} from '..';

const Loading: FC<IProps> = (props) => {
    return (
        <div
            className={
                classNames(styles.loading, props.className, {
                    [styles.isPage]: props.isPage,
                })
            }
        >
            {
                props.isPage &&
                <Header/>
            }
            <ClipLoader
                color={'#1154D7'}
                loading={true}
                size={props.size ?? 100}
            />
        </div>
    );
};

export default Loading;
