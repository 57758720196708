import {
    IPostBody,
} from '../types';
import {
    IPostGetOneQuery,
    IPostGetQuery,
} from './types/requests';
import {
    IPostGet,
    IPostGetOne,
} from './types/responses';

import config from 'config';

import Fetch from 'tools/Fetch';

import {
    ENTITY_PATH,
} from './constants';

export async function postGet(query: IPostGetQuery, body: IPostBody): Promise<IPostGet> {
    return new Fetch<IPostGet>({
        url: `${config.URL}${ENTITY_PATH}`,
        method: 'POST',
        query,
        body,
    })
        .on([200], (body) => {
            return {paymentMethods: body};
        })
        .on([400, 401, 403, 500], (body) => {
            return body;
        })
        .exec();
}

export async function postGetOne(id: string, query: IPostGetOneQuery, body: IPostBody): Promise<IPostGetOne> {
    return new Fetch<IPostGetOne>({
        url: `${config.URL}${ENTITY_PATH}/${id}`,
        method: 'POST',
        query,
        body,
    })
        .on([200], (body) => {
            return {paymentMethod: body};
        })
        .on([404], (body) => {
            return {notFoundError: body.error};
        })
        .on([400, 401, 403, 500], (body) => {
            return body;
        })
        .exec();
}
