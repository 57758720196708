export interface IInitial {
    type: string;
    publicKey: string;
    successRedirectUrl: string;
    failureRedirectUrl: string;
}

export default function(): Promise<IInitial> {
    return new Promise<IInitial>((resolve, reject) => {
        const timeoutId = setTimeout(() => {
            reject('Initial data is required');
        }, 10_000);

        window.addEventListener('message', function(e) {
            if (e.data.type !== 'receiveInitial') {
                return;
            }
            if (!e.data) {
                return;
            }

            clearTimeout(timeoutId);
            resolve(e.data);
        });
        window.parent.postMessage({
            type: 'getInitial',
        }, '*');
    });
}
