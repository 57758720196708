import {
    IRef,
} from './types';
import {
    IPostPayInBody,
    IPostPayOutBody,
} from 'entities/payments/types/requests';

import {
    useDefaultedRef,
} from 'tools/hooks';

export function usePaymentFormRef() {
    return useDefaultedRef<IRef>({
        async getPostPayInBody(): Promise<IPostPayInBody | null> {
            return null;
        },
        async getPostPayOutBody(): Promise<IPostPayOutBody | null> {
            return null;
        },
        validate(): boolean {
            return true;
        },
    });
}
