import {
    IProps,
} from './types';

import classNames from 'classnames';
import React, {
    FC,
} from 'react';

import dinoImage from './media/dino.svg';

import styles from './NotFound.module.scss';

const NotFound: FC<IProps> = (props) => {
    return (
        <div className={classNames(styles.notFound, props.className)}>
            <img
                className={styles.dinoImage}
                src={dinoImage}
                alt={''}
            />
            <div className={styles.title}>
                Error № 404
            </div>
            <div className={styles.subtitle}>
                The requested URL was not found on this
                <br/>
                server.
            </div>
        </div>
    );
};

export default NotFound;
