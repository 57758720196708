import {
    IPostByUserBody,
} from './types/requests';
import {
    IPostByUser,
} from './types/responses';

import config from 'config';

import Fetch from 'tools/Fetch';

import {
    AUTH_HASH_FIELD_NAME,
    AUTH_PUBLIC_KEY_FIELD_NAME,
} from '../constants';

import {
    ENTITY_PATH,
} from './constants';

export async function postByUser(body: IPostByUserBody): Promise<IPostByUser> {
    const formData = new FormData();

    formData.set(AUTH_PUBLIC_KEY_FIELD_NAME, body[AUTH_PUBLIC_KEY_FIELD_NAME]);
    formData.set(AUTH_HASH_FIELD_NAME, body[AUTH_HASH_FIELD_NAME]);
    formData.set('file', body.file);

    return new Fetch<IPostByUser>({
        url: `${config.URL}${ENTITY_PATH}`,
        method: 'POST',
        formData,
    })
        .on([200], (body) => {
            return {paymentAttachment: body};
        })
        .on([400, 401, 403, 500], (body) => {
            return body;
        })
        .exec();
}
