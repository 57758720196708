import {
    IPostBody,
} from 'entities/types';

import React, {
    FC,
    useEffect,
} from 'react';
import {
    useLocation,
    useNavigate,
} from 'react-router-dom';

import {
    useQuery,
} from 'tools/hooks';

import {
    requireHash,
} from 'helpers/functions';

import {
    AUTH_PUBLIC_KEY_FIELD_NAME,
} from 'entities/constants';
import {
    useInitial,
} from 'entities/hooks';
import {
    usePaymentMethods,
} from 'entities/paymentMethods/hooks';

import {
    Header,
    Loading,
} from 'components/other';

import PaymentMethod from './PaymentMethod';

import {
    QUERY_DESCRIPTION,
} from './constants';

import arrowImage from './media/arrow.svg';

import styles from './PaymentMethods.module.scss';

const PaymentMethods: FC = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const [query] = useQuery({
        query: location.search,
        queryDescription: QUERY_DESCRIPTION,
        queryPusher: (query) => navigate({
            search: query,
        }),
    });

    const initial = useInitial();
    const {
        paymentMethods,
        postGetPaymentMethods,
    } = usePaymentMethods();

    useEffect(() => {
        if (!initial) {
            return;
        }

        (async () => {
            const body: Omit<IPostBody, 'hash'> = {
                [AUTH_PUBLIC_KEY_FIELD_NAME]: initial.publicKey,
            };
            const hash = await requireHash(body);

            await postGetPaymentMethods(query, {
                ...body,
                hash,
            });
        })();
    }, [initial]);

    if (!initial || !paymentMethods) {
        return (
            <Loading isPage={true}/>
        );
    }

    return (
        <div className={styles.paymentMethods}>
            <Header className={styles.header}>
                <div className={styles.headerContent}>
                    <img
                        className={styles.arrowImage}
                        src={arrowImage}
                        alt={''}
                    />
                    CHOOSE PAYMENT METHOD
                </div>
            </Header>
            <div className={styles.table}>
                {
                    paymentMethods.map(
                        (paymentMethod) =>
                            <PaymentMethod
                                key={paymentMethod.id}
                                className={styles.paymentMethod}
                                paymentMethod={paymentMethod}
                                locale={query.locale}
                            />
                    )
                }
            </div>
        </div>
    );
};

export default PaymentMethods;
