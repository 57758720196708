import {
    IProps,
} from './types';

import classNames from 'classnames';
import React, {
    FC,
    useState,
} from 'react';

import {
    useTimeout,
} from 'tools/hooks';

import {
    BaseButton,
} from 'components/buttons';

import {
    COPIED_DELAY_MS,
} from './constants';

import checkImage from './media/check.svg';

import styles from './FieldDisplay.module.scss';

const FieldDisplay: FC<IProps> = (props) => {
    const [isCopied, setIsCopied] = useState(false);

    const onCopy = async () => {
        setIsCopied(true);
        await navigator.clipboard.writeText(props.value);
        keepTimeout();
    };

    const {keepTimeout} = useTimeout(COPIED_DELAY_MS, () => {
        setIsCopied(false);
    });

    return (
        <div className={classNames(styles.fieldDisplay, props.className)}>
            <div className={styles.content}>
                <div className={styles.label}>
                    {props.label}
                </div>
                <div className={styles.value}>
                    {props.value}
                </div>
            </div>
            {
                isCopied ?
                    <div className={styles.copied}>
                        <img
                            src={checkImage}
                            alt={''}
                        />
                        Copied
                    </div> :
                    <BaseButton
                        className={styles.copyButton}
                        onClick={onCopy}
                    >
                        Copy
                    </BaseButton>
            }
        </div>
    );
};

export default FieldDisplay;
