import {
    IPostGetQuery,
} from 'entities/paymentMethods/types/requests';

import {
    IQueryDescription,
} from 'tools/Query';

import {
    DEFAULT_LOCALE,
    PAYMENT_METHOD_TYPE,
} from 'entities/paymentMethods/constants';

export const QUERY_DESCRIPTION: IQueryDescription<IPostGetQuery> = {
    type: {
        type: 'array',
        isRequired: false,
        isNullable: false,
        default: [],
        items: {
            type: 'string',
            isRequired: true,
            isNullable: false,
            enum: Object.values(PAYMENT_METHOD_TYPE),
            default: '',
        },
    },
    locale: {
        type: 'string',
        isRequired: false,
        isNullable: true,
        default: DEFAULT_LOCALE,
    },
};
