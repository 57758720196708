import {
    useEffect,
    useState,
} from 'react';

import {
    IInitial,
    requireInitial,
} from 'helpers/functions';

export function useInitial() {
    const [initial, setInitial] = useState<IInitial | null>(null);

    useEffect(() => {
        (async () => {
            const initial = await requireInitial();

            setInitial(initial);
        })();
    }, []);

    return initial;
}
