import {
    IPostBody,
} from '../types';
import {
    IPaymentMethod,
} from './types';
import {
    IPostGetOneQuery,
    IPostGetQuery,
} from './types/requests';
import {
    IPostGet,
    IPostGetOne,
} from './types/responses';

import {
    useState,
} from 'react';

import * as fetches from './fetches';

export function usePaymentMethods() {
    const [paymentMethods, setPaymentMethods] = useState<IPaymentMethod[] | null>(null);

    const postGetPaymentMethods = async (query: IPostGetQuery, body: IPostBody): Promise<IPostGet> => {
        const res = await fetches.postGet(query, body);

        if (!res.paymentMethods || res.error) {
            console.log(res.error);

            return res;
        }

        setPaymentMethods(res.paymentMethods);

        return res;
    };

    return {
        paymentMethods,
        postGetPaymentMethods,
    };
}

export function usePaymentMethod() {
    const [paymentMethod, setPaymentMethod] = useState<IPaymentMethod | null>(null);
    const [isNotFoundError, setIsNotFoundError] = useState(false);

    const postGetPaymentMethod = async (id: string, query: IPostGetOneQuery, body: IPostBody): Promise<IPostGetOne> => {
        const res = await fetches.postGetOne(id, query, body);

        if (res.notFoundError) {
            setIsNotFoundError(true);

            return res;
        }
        if (!res.paymentMethod || res.error) {
            console.log(res.error);

            return res;
        }

        setPaymentMethod(res.paymentMethod);

        return res;
    };

    return {
        paymentMethod,
        isNotFoundError,
        postGetPaymentMethod,
    };
}
