export function formatValue(value: string): string | null {
    if (!value) {
        return null;
    }

    return value;
}

export function formatInputValue(value: string | null): string {
    if (!value) {
        return '';
    }

    return value;
}
