import {
    IProps,
} from './types';

import classNames from 'classnames';
import React, {
    FC,
} from 'react';

import styles from './Card.module.scss';

const Card: FC<IProps> = (props) => {
    return (
        <div className={classNames(styles.card, props.className)}>
            <div className={styles.header}>
                <div className={styles.order}>
                    {props.order}
                </div>
                <div className={styles.label}>
                    {props.label}
                </div>
            </div>
            <div className={styles.content}>
                {props.children}
            </div>
        </div>
    );
};

export default Card;
