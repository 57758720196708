import {
    IClientFieldsValue,
} from './types';
import {
    IClientField,
} from 'entities/paymentMethods/types';

export function formatClientFieldsValues(clientFields: IClientField[]): IClientFieldsValue[] {
    return clientFields.map((clientField) => {
        return {
            key: clientField.key,
            value: '',
        };
    });
}
