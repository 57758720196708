import {
    IProps,
} from './types';

import classNames from 'classnames';
import React, {
    FC,
} from 'react';

import shieldImage from './media/shield.svg';

import styles from './Footer.module.scss';

const Footer: FC<IProps> = (props) => {
    return (
        <div className={classNames(styles.footer, props.className)}>
            <img
                className={styles.shield}
                src={shieldImage}
                alt={''}
            />
            <span className={styles.text}>
                Secure by 1stpay
            </span>
        </div>
    );
};

export default Footer;
