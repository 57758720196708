import {
    useState,
} from 'react';

export default function useTimeout(delay: number, cb: () => void) {
    const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);

    const keepTimeout = () => {
        if (timeoutId !== null) {
            clearTimeout(timeoutId);
        }

        const newTimeoutId = setTimeout(() => {
            setTimeoutId(null);
            cb();
        }, delay);

        setTimeoutId(newTimeoutId);
    };

    return {
        keepTimeout,
    };
}
