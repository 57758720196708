import {
    IProps,
} from './types';

import classNames from 'classnames';
import React, {
    FC,
} from 'react';
import {
    Link,
} from 'react-router-dom';

import styles from './PaymentMethod.module.scss';

const PaymentMethod: FC<IProps> = (props) => {
    return (
        <Link
            className={classNames(styles.paymentMethod, props.className)}
            to={`/${props.paymentMethod.id}?locale=${props.locale}`}
        >
            <img
                src={props.paymentMethod.logoUrl}
                alt={props.paymentMethod.name}
            />
        </Link>
    );
};

export default PaymentMethod;
