import {
    ERROR,
} from './constants';

export function formatError(error: ERROR | null): string {
    switch (error) {
        case ERROR.IS_REQUIRED: {
            return 'Is required';
        }
        case ERROR.TOO_FEW: {
            return 'Too few';
        }
        case ERROR.TOO_MUCH: {
            return 'Too much';
        }
        case null: {
            return '';
        }
    }
}
