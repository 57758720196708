import {
    IParams,
} from './types';
import {
    IPostBody,
} from 'entities/types';

import React, {
    FC,
    useEffect,
    useState,
} from 'react';
import {
    useLocation,
    useNavigate,
    useParams,
} from 'react-router-dom';

import {
    useQuery,
} from 'tools/hooks';

import {
    requireHash,
} from 'helpers/functions';

import {
    AUTH_PUBLIC_KEY_FIELD_NAME,
} from 'entities/constants';
import {
    useInitial,
} from 'entities/hooks';
import {
    PAYMENT_METHOD_TYPE,
} from 'entities/paymentMethods/constants';
import {
    usePaymentMethod,
} from 'entities/paymentMethods/hooks';
import {
    usePayment,
} from 'entities/payments/hooks';

import {
    Header,
    Loading,
} from 'components/other';

import BackButton from './BackButton';
import Footer from './Footer';
import NotFound from './NotFound';
import PaymentForm, {
    usePaymentFormRef,
} from './PaymentForm';
import Success from './Success';

import {
    QUERY_DESCRIPTION,
} from './constants';
import {
    delayRedirect,
} from './functions';

import styles from './Payment.module.scss';

const Payment: FC = () => {
    const [isSuccess, setIsSuccess] = useState(false);

    const location = useLocation();
    const navigate = useNavigate();

    const [query] = useQuery({
        query: location.search,
        queryDescription: QUERY_DESCRIPTION,
        queryPusher: (query) => navigate({
            search: query,
        }),
    });

    const {paymentMethodId} = useParams<IParams>();
    const initial = useInitial();
    const {
        paymentMethod,
        isNotFoundError,
        postGetPaymentMethod,
    } = usePaymentMethod();

    const {
        postPayInPayment,
        postPayOutPayment,
    } = usePayment();

    const paymentFormRef = usePaymentFormRef();

    const onSubmit = async () => {
        if (!initial) {
            return;
        }
        if (!paymentMethod) {
            return;
        }
        if (!paymentFormRef.current.validate()) {
            return;
        }

        switch (paymentMethod.type) {
            case PAYMENT_METHOD_TYPE.PAY_IN: {
                const body = await paymentFormRef.current.getPostPayInBody();

                if (!body) {
                    return;
                }

                const res = await postPayInPayment(body);

                if (res.formUrl) {
                    return;
                }
                if (res.error) {
                    await delayRedirect(initial.failureRedirectUrl);

                    return;
                }

                setIsSuccess(true);
                await delayRedirect(initial.successRedirectUrl);

                break;
            }
            case PAYMENT_METHOD_TYPE.PAY_OUT: {
                const body = await paymentFormRef.current.getPostPayOutBody();

                if (!body) {
                    return;
                }

                const res = await postPayOutPayment(body);

                if (res.error) {
                    await delayRedirect(initial.failureRedirectUrl);

                    return;
                }

                setIsSuccess(true);
                await delayRedirect(initial.successRedirectUrl);

                break;
            }
        }
    };

    useEffect(() => {
        if (!paymentMethodId) {
            return;
        }
        if (!initial) {
            return;
        }

        (async () => {
            const body: Omit<IPostBody, 'hash'> = {
                [AUTH_PUBLIC_KEY_FIELD_NAME]: initial.publicKey,
            };
            const hash = await requireHash(body);

            await postGetPaymentMethod(paymentMethodId, query, {
                ...body,
                hash,
            });
        })();
    }, [paymentMethodId, initial]);

    if (!initial || !paymentMethod) {
        return (
            <Loading isPage={true}/>
        );
    }

    return (
        <div className={styles.payment}>
            <Header>
                <BackButton locale={query.locale}/>
            </Header>
            <div className={styles.content}>
                {
                    isNotFoundError ?
                        <NotFound/> :
                        isSuccess ?
                            <Success/> :
                            <PaymentForm
                                ref={paymentFormRef}
                                publicKey={initial.publicKey}
                                successRedirectUrl={initial.successRedirectUrl}
                                failureRedirectUrl={initial.failureRedirectUrl}
                                paymentMethod={paymentMethod}
                                onSubmit={onSubmit}
                            />
                }
                <Footer className={styles.footer}/>
            </div>
        </div>
    );
};

export default Payment;
