import {
    IRef,
} from './types';

import {
    useDefaultedRef,
} from 'tools/hooks';

export function useAmountInputRef() {
    return useDefaultedRef<IRef>({
        getValue(): number {
            return NaN;
        },
        validate(): boolean {
            return true;
        },
    });
}
