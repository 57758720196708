import {
    IProps,
} from './types';

import classNames from 'classnames';
import React, {
    FC,
} from 'react';
import {
    Link,
} from 'react-router-dom';

import backImage from './media/back.svg';

import styles from './BackButton.module.scss';

const BackButton: FC<IProps> = (props) => {
    return (
        <Link
            className={classNames(styles.backButton, props.className)}
            to={`/?locale=${props.locale}`}
        >
            <img
                className={styles.image}
                src={backImage}
                alt={''}
            />
            <span>
                Back
            </span>
        </Link>
    );
};

export default BackButton;
