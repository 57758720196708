import {
    IPostPayInBody,
    IPostPayOutBody,
} from './types/requests';
import {
    IPostPayIn,
    IPostPayOut,
} from './types/responses';

import {
    locationReplaceParent,
} from 'helpers/functions';

import * as fetches from './fetches';

export function usePayment() {
    const postPayInPayment = async (body: IPostPayInBody): Promise<IPostPayIn> => {
        const res = await fetches.postPayIn(body);

        if (res.formUrl) {
            locationReplaceParent(res.formUrl);

            return res;
        }
        if (res.error) {
            console.log(res.error);

            return res;
        }

        return res;
    };
    const postPayOutPayment = async (body: IPostPayOutBody): Promise<IPostPayOut> => {
        const res = await fetches.postPayOut(body);

        if (res.error) {
            console.log(res.error);

            return res;
        }

        return res;
    };

    return {
        postPayInPayment,
        postPayOutPayment,
    };
}
