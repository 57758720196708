import {
    IProps,
    IRef,
} from './types';
import {
    IPostPayInBody,
    IPostPayOutBody,
} from 'entities/payments/types/requests';

import classNames from 'classnames';
import React, {
    forwardRef,
    useImperativeHandle,
    useState,
} from 'react';

import {
    useRefsArray,
} from 'tools/hooks';

import {
    requireHash,
} from 'helpers/functions';

import {
    AUTH_HASH_FIELD_NAME,
    AUTH_PUBLIC_KEY_FIELD_NAME,
} from 'entities/constants';

import {
    PrimaryButton,
} from 'components/buttons';

import AmountInput, {
    useAmountInputRef,
} from './AmountInput';
import AttachmentInput, {
    useAttachmentInputRef,
} from './AttachmentInput';
import Card from './Card';
import ClientFieldInput from './ClientFieldInput';
import FieldDisplay from './FieldDisplay';

import {
    formatClientFieldsValues,
} from './functions';

import styles from './PaymentForm.module.scss';

const PaymentForm = forwardRef<IRef, IProps>((props, ref) => {
    const [isPending, setIsPending] = useState(false);

    const amountInputRef = useAmountInputRef();
    const {
        refs,
        validateRefs,
        extractRefs,
    } = useRefsArray(formatClientFieldsValues(props.paymentMethod.clientFields));
    const attachmentInputRef = useAttachmentInputRef();

    const onSubmit = async () => {
        if (isPending) {
            return;
        }

        setIsPending(true);
        await props.onSubmit();
        setIsPending(false);
    };

    useImperativeHandle(ref, () => {
        return {
            async getPostPayInBody(): Promise<IPostPayInBody | null> {
                const body = {
                    accountId: props.paymentMethod.account?.id ?? 0,
                    paymentMethodId: props.paymentMethod.id,
                    amount: amountInputRef.current.getValue(),
                    clientFieldsValues: extractRefs(),
                    successRedirectUrl: props.successRedirectUrl,
                    failureRedirectUrl: props.failureRedirectUrl,
                    attachmentId: attachmentInputRef.current.getValue(),
                    [AUTH_PUBLIC_KEY_FIELD_NAME]: props.publicKey,
                };

                return {
                    ...body,
                    [AUTH_HASH_FIELD_NAME]: await requireHash(body),
                };
            },
            async getPostPayOutBody(): Promise<IPostPayOutBody | null> {
                const body = {
                    accountId: props.paymentMethod.account?.id ?? 0,
                    paymentMethodId: props.paymentMethod.id,
                    amount: amountInputRef.current.getValue(),
                    clientFieldsValues: extractRefs(),
                    successRedirectUrl: props.successRedirectUrl,
                    failureRedirectUrl: props.failureRedirectUrl,
                    attachmentId: attachmentInputRef.current.getValue(),
                    [AUTH_PUBLIC_KEY_FIELD_NAME]: props.publicKey,
                };

                return {
                    ...body,
                    [AUTH_HASH_FIELD_NAME]: await requireHash(body),
                };
            },
            validate(): boolean {
                return ![
                    amountInputRef.current.validate(),
                    validateRefs(),
                    attachmentInputRef.current.validate(),
                ].includes(false);
            },
        };
    });

    return (
        <div className={classNames(styles.paymentForm, props.className)}>
            <Card
                className={styles.amountCard}
                label={'Enter the amount'}
                order={1}
            >
                <AmountInput
                    ref={amountInputRef}
                    paymentMethod={props.paymentMethod}
                    isDisabled={isPending}
                />
            </Card>
            {
                !!props.paymentMethod.account?.detailFields.length &&
                <Card
                    className={styles.detailFieldsCard}
                    label={'Copy and send funds'}
                    order={2}
                >
                    {
                        props.paymentMethod.detailFieldsHint &&
                        <div className={styles.subtitle}>
                            {props.paymentMethod.detailFieldsHint}
                        </div>
                    }
                    <div className={styles.detailFieldsList}>
                        {
                            props.paymentMethod.account?.detailFields.map(
                                (detailField) =>
                                    <FieldDisplay
                                        key={detailField.key}
                                        label={detailField.name}
                                        value={detailField.value}
                                    />
                            )
                        }
                    </div>
                </Card>
            }
            {
                !!props.paymentMethod.clientFields.length &&
                <Card
                    className={styles.clientFieldsCard}
                    label={'Insert transaction details'}
                    order={props.paymentMethod.account?.detailFields.length ? 3 : 2}
                >
                    {
                        props.paymentMethod.clientFieldsHint &&
                        <div className={styles.subtitle}>
                            {props.paymentMethod.clientFieldsHint}
                        </div>
                    }
                    <div className={styles.clientFieldsList}>
                        {
                            refs.map(
                                (ref, i) =>
                                    <ClientFieldInput
                                        ref={ref}
                                        key={props.paymentMethod.clientFields[i].key}
                                        clientField={props.paymentMethod.clientFields[i]}
                                        isDisabled={isPending}
                                    />
                            )
                        }
                    </div>
                </Card>
            }
            {
                props.paymentMethod.isAttachmentRequired &&
                <AttachmentInput
                    ref={attachmentInputRef}
                    className={styles.attachmentInput}
                    publicKey={props.publicKey}
                    isDisabled={isPending}
                />
            }
            <PrimaryButton
                className={styles.submitButton}
                isDisabled={isPending}
                onClick={onSubmit}
            >
                Pay
            </PrimaryButton>
        </div>
    );
});

export default PaymentForm;
