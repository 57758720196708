import {
    IPostGetOneQuery,
} from 'entities/paymentMethods/types/requests';

import {
    IQueryDescription,
} from 'tools/Query';

import {
    DEFAULT_LOCALE,
} from 'entities/paymentMethods/constants';

export const REDIRECT_DELAY_MS = 3_000;

export const QUERY_DESCRIPTION: IQueryDescription<IPostGetOneQuery> = {
    locale: {
        type: 'string',
        isRequired: false,
        isNullable: true,
        default: DEFAULT_LOCALE,
    },
};
