import {
    IProps,
} from './types';

import React, {
    FC,
} from 'react';

import {
    MAX_LENGTH,
} from './constants';
import {
    formatValue,
} from './functions';

import {
    StringInput,
} from '..';

const NumberInput: FC<IProps> = (props) => {
    const onChangeValue = (value: string) => {
        if (!value) {
            props.onChangeValue(null);

            return;
        }
        if (!/\d+/.test(value)) {
            return;
        }
        if (value.length > MAX_LENGTH) {
            return;
        }

        props.onChangeValue(parseInt(value));
    };

    return (
        <StringInput
            {...props}
            value={formatValue(props.value)}
            onChangeValue={onChangeValue}
        />
    );
};

export default NumberInput;
