import {
    IProps,
} from './types';

import classNames from 'classnames';
import React, {
    FC,
} from 'react';

import styles from './BaseButton.module.scss';

const BaseButton: FC<IProps> = (props) => {
    return (
        <button
            className={
                classNames(styles.baseButton, props.className, {
                    [styles.isDisabled]: props.isDisabled,
                })
            }
            disabled={props.isDisabled}
            onClick={props.onClick}
        >
            {props.children}
        </button>
    );
};

export default BaseButton;
