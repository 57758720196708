import {
    ERROR,
} from './constants';

export function formatError(error: ERROR | null): string {
    switch (error) {
        case ERROR.IS_REQUIRED: {
            return 'Is required';
        }
        case null: {
            return '';
        }
    }
}
