import {
    IClientFieldsValue,
} from '../../types';
import {
    IProps,
    IRef,
} from './types';

import React, {
    forwardRef,
    useImperativeHandle,
    useState,
} from 'react';

import {
    StringInput,
} from 'components/inputs';

import {
    ERROR,
} from './constants';
import {
    formatError,
    formatValue,
} from './functions';

const NumberFieldInput = forwardRef<IRef, IProps>((props, ref) => {
    const [value, setValue] = useState('');
    const [error, setError] = useState<ERROR | null>(null);

    const onChangeValue = async (value: string) => {
        if (!/\d+/.test(value)) {
            return;
        }

        setValue(value);
        setError(null);
    };

    useImperativeHandle(ref, () => {
        return {
            getValue(): IClientFieldsValue {
                return {
                    key: props.fieldKey,
                    value: formatValue(value),
                };
            },
            setValue(value: IClientFieldsValue | null) {
                setValue(value?.value ?? '');
            },
            validate(): boolean {
                const formattedValue = formatValue(value);

                if (!formattedValue) {
                    setError(ERROR.IS_REQUIRED);

                    return false;
                }
                if (formattedValue.length < props.validation.minLength) {
                    setError(ERROR.MIN_LENGTH);

                    return false;
                }
                if (formattedValue.length > props.validation.maxLength) {
                    setError(ERROR.MAX_LENGTH);

                    return false;
                }

                setError(null);

                return true;
            },
        };
    });

    return (
        <StringInput
            className={props.className}
            label={props.name}
            value={value}
            isDisabled={props.isDisabled}
            isError={!!error}
            errorMessage={formatError(error, props.validation)}
            onChangeValue={onChangeValue}
        />
    );
});

export default NumberFieldInput;
