import {
    locationReplaceParent,
} from 'helpers/functions';

import {
    REDIRECT_DELAY_MS,
} from './constants';

export function delayRedirect(url: string): Promise<void> {
    return new Promise<void>((resolve) => {
        setTimeout(() => {
            locationReplaceParent(url);
            resolve();
        }, REDIRECT_DELAY_MS);
    });
}
