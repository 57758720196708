import {
    IPaymentAttachment,
} from './types';
import {
    IPostByUserBody,
} from './types/requests';
import {
    IPostByUser,
} from './types/responses';

import {
    useState,
} from 'react';

import * as fetches from './fetches';

export function usePaymentAttachment() {
    const [paymentAttachment, setPaymentAttachment] = useState<IPaymentAttachment | null>(null);

    const postPaymentAttachment = async (body: IPostByUserBody): Promise<IPostByUser> => {
        const res = await fetches.postByUser(body);

        if (!res.paymentAttachment || res.error) {
            console.log(res.error);

            return res;
        }

        setPaymentAttachment(res.paymentAttachment);

        return res;
    };

    return {
        paymentAttachment,
        postPaymentAttachment,
    };
}
