import {
    IProps,
    IRef,
} from './types';

import React, {
    forwardRef,
} from 'react';

import {
    CLIENT_FIELD_TYPE,
} from 'entities/paymentMethods/constants';

import DateTimeFieldInput from './DateTimeFieldInput';
import NumberFieldInput from './NumberFieldInput';
import RegexFieldInput from './RegexFieldInput';
import StringFieldInput from './StringFieldInput';

const ClientFieldInput = forwardRef<IRef, IProps>((props, ref) => {
    switch (props.clientField.validation.type) {
        case CLIENT_FIELD_TYPE.STRING: {
            return (
                <StringFieldInput
                    ref={ref}
                    fieldKey={props.clientField.key}
                    className={props.className}
                    name={props.clientField.name}
                    validation={props.clientField.validation}
                    isDisabled={props.isDisabled}
                />
            );
        }
        case CLIENT_FIELD_TYPE.NUMBER: {
            return (
                <NumberFieldInput
                    ref={ref}
                    fieldKey={props.clientField.key}
                    className={props.className}
                    name={props.clientField.name}
                    validation={props.clientField.validation}
                    isDisabled={props.isDisabled}
                />
            );
        }
        case CLIENT_FIELD_TYPE.REGEX: {
            return (
                <RegexFieldInput
                    ref={ref}
                    fieldKey={props.clientField.key}
                    className={props.className}
                    name={props.clientField.name}
                    validation={props.clientField.validation}
                    isDisabled={props.isDisabled}
                />
            );
        }
        case CLIENT_FIELD_TYPE.DATETIME: {
            return (
                <DateTimeFieldInput
                    ref={ref}
                    fieldKey={props.clientField.key}
                    className={props.className}
                    name={props.clientField.name}
                    validation={props.clientField.validation}
                    isDisabled={props.isDisabled}
                />
            );
        }
    }
});

export default ClientFieldInput;
