import {
    IPostPayInBody,
    IPostPayOutBody,
} from './types/requests';
import {
    IPostPayIn,
    IPostPayOut,
} from './types/responses';

import config from 'config';

import Fetch from 'tools/Fetch';

import {
    ENTITY_PATH,
} from './constants';

export async function postPayIn(body: IPostPayInBody): Promise<IPostPayIn> {
    return new Fetch<IPostPayIn>({
        url: `${config.URL}${ENTITY_PATH}/pay_in`,
        method: 'POST',
        body,
    })
        .on([200], (body) => {
            return body;
        })
        .on([400, 401, 403, 500], (body) => {
            return body;
        })
        .exec();
}

export async function postPayOut(body: IPostPayOutBody): Promise<IPostPayOut> {
    return new Fetch<IPostPayOut>({
        url: `${config.URL}${ENTITY_PATH}/pay_out`,
        method: 'POST',
        body,
    })
        .on([200], (body) => {
            return body;
        })
        .on([400, 401, 403, 500], (body) => {
            return body;
        })
        .exec();
}
