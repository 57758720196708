type IAcceptType = 'image' | 'svg' | 'video' | 'audio' | 'file' | 'any';

export default function(acceptTypes: IAcceptType[], isMultiple: boolean): Promise<File[]> {
    return new Promise<File[]>((resolve) => {
        function handleChangeEvent(e: Event): void {
            // @ts-ignore
            resolve(e.target?.files ? [...e.target.files] : []);
            // @ts-ignore
            e.target.value = null;

            input.removeEventListener('change', handleChangeEvent);
            window.removeEventListener('focus', handleCloseFileWindow);
        }

        function handleCloseFileWindow(): void {
            setTimeout(() => {
                resolve([]);
                input.removeEventListener('change', handleChangeEvent);
                window.removeEventListener('focus', handleCloseFileWindow);
            }, 1000);
        }

        const id = 'single_file_input';
        let input = document.getElementById(id) as HTMLInputElement;

        if (!input) {
            input = document.createElement('input');

            input.id = id;
            input.type = 'file';
            input.multiple = isMultiple;
            input.style.display = 'none';

            document.body.appendChild(input);
        }

        let accept = '';

        acceptTypes.forEach((acceptType) => {
            switch (acceptType) {
                case 'image': {
                    accept += '.jpg,.jpeg,.png,';
                    break;
                }
                case 'svg': {
                    accept += '.svg,';
                    break;
                }
                case 'video': {
                    accept += '.mp4,.mov,';
                    break;
                }
                case 'audio': {
                    accept += '.mp3,';
                    break;
                }
                case 'file': {
                    accept += '.txt,.rtf,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.pdf,.djvu,.fb2,.psd,.zip,.7z';
                    break;
                }
                case 'any': {
                    break;
                }
            }
        });

        input.accept = accept;

        input.addEventListener('change', handleChangeEvent, {once: true});
        window.addEventListener('focus', handleCloseFileWindow, {once: true});

        input.click();
    });
}
