import {
    IStringFieldValidation,
} from 'entities/paymentMethods/types';

import {
    ERROR,
} from './constants';

export function formatValue(value: string): string {
    return value.trim();
}

export function formatError(error: ERROR | null, validation: IStringFieldValidation): string {
    switch (error) {
        case ERROR.IS_REQUIRED: {
            return 'Is required';
        }
        case ERROR.MIN_LENGTH: {
            return `Min length: ${validation.minLength}`;
        }
        case ERROR.MAX_LENGTH: {
            return `Max length: ${validation.maxLength}`;
        }
        case null: {
            return '';
        }
    }
}
