import {
    IProps,
} from './types';

import classNames from 'classnames';
import React, {
    FC,
    useRef,
    useState,
} from 'react';

import {
    useClickOutside,
} from 'tools/hooks';

import {
    ErrorMessage,
} from 'components/other';

import styles from './StringInput.module.scss';

const StringInput: FC<IProps> = (props) => {
    const [isFocused, setIsFocused] = useState(false);

    const contentRef = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);

    const onClickContent = () => {
        setIsFocused(true);
        inputRef.current?.focus();
    };

    useClickOutside(contentRef, () => {
        setIsFocused(false);
    });

    return (
        <div
            className={
                classNames(styles.stringInput, props.className, {
                    [styles.isValuedFocused]: isFocused || !!props.value,
                    [styles.isError]: props.isError,
                })
            }
        >
            <div
                ref={contentRef}
                className={styles.content}
                onClick={onClickContent}
            >
                {
                    props.label &&
                    <div className={styles.label}>
                        {props.label}
                    </div>
                }
                <input
                    ref={inputRef}
                    className={styles.input}
                    type={props.type}
                    value={props.value}
                    disabled={props.isDisabled}
                    onChange={(e) => props.onChangeValue(e.target.value)}
                />
            </div>
            <ErrorMessage text={props.errorMessage ?? ''}/>
        </div>
    );
};

export default StringInput;
