export default async function<IBody extends Record<string, any>>(body: IBody): Promise<string> {
    return new Promise<string>((resolve, reject) => {
        const timeoutId = setTimeout(() => {
            reject('Hash is required');
        }, 10_000);

        window.addEventListener('message', function(e) {
            if (e.data.type !== 'hash') {
                return;
            }
            if (!e.data.hash) {
                return;
            }

            clearTimeout(timeoutId);
            resolve(e.data.hash);
        });
        window.parent.postMessage({
            type: 'subscribe',
            body,
        }, '*');
    });
}
