import {
    IProps,
} from './types';

import classNames from 'classnames';
import React, {
    FC,
} from 'react';

import styles from './Header.module.scss';

const Header: FC<IProps> = (props) => {
    return (
        <div className={classNames(styles.header, props.className)}>
            {props.children}
        </div>
    );
};

export default Header;
