import {
    IProps,
} from './types';

import classNames from 'classnames';
import React, {
    FC,
} from 'react';

import checkImage from './media/check.svg';
import successImage from './media/success.svg';

import styles from './Success.module.scss';

const Success: FC<IProps> = (props) => {
    return (
        <div className={classNames(styles.success, props.className)}>
            <img
                className={styles.successImage}
                src={successImage}
                alt={''}
            />
            <img
                className={styles.checkImage}
                src={checkImage}
                alt={''}
            />
            <div className={styles.text}>
                <div className={styles.title}>
                    Success!
                </div>
                <div className={styles.subtitle}>
                    Your balance will be updated soon
                </div>
                <div className={styles.subtitle}>
                    You will be redirected in a few seconds
                </div>
            </div>
        </div>
    );
};

export default Success;
