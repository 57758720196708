import {
    IProps,
} from './types';

import React, {
    FC,
} from 'react';

import {
    formatInputValue,
    formatValue,
} from './functions';

import {
    StringInput,
} from '..';

const DateTimeInput: FC<IProps> = (props) => {
    const onChangeValue = (value: string) => {
        const formattedValue = formatValue(value);

        props.onChangeValue(formattedValue);
    };

    return (
        <StringInput
            {...props}
            type={'datetime-local'}
            value={formatInputValue(props.value)}
            onChangeValue={onChangeValue}
        />
    );
};

export default DateTimeInput;
